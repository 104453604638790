Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiPUTMethod="PUT"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStatesendPoint="account_block/show_country"
exports.editAdminDetail="account_block/find_account"
exports.updateAdminDataEndPoint="account_block/accounts"

exports.updateAdminProfileEndPoint="bx_block_customisableuserprofiles/admin_profile/update_admin_profile";
exports.updateAdminProfileAPiMethod="PUT";
exports.updateAdminProfileContentType="multipart/form-data"

exports.updateImageEndpoint="account_block/accounts/upload_profile_pic";
exports.updateImageAPiMethod="PUT";
exports.updateImageContentType="multipart/form-data"

exports.getAdminProfileEndPoint="bx_block_customisableuserprofiles/admin_profile/admin_profile_detail";
exports.getAdminProfileElearningDataEndPoint="bx_block_gamification/elearnings"
exports.getAdminProfileAPiMethod="GET"

exports.executiveProduct="Executive-Product";
exports.goodPoints="Good Points";
exports.negativePoints="Negative Points";
exports.badgesEarned="Badges Earned";
exports.employeeID="Employee ID";
exports.designation="Designation";
exports.location="Location";
exports.mobileNumber="Mobile Number";
exports.emailID="Email ID";
exports.country="Country";
exports.state="State";
exports.timeZone="Time Zone";
exports.timings="Timings";
exports.general="General";
exports.afternoonShift="Afternoon Shift";
exports.nightShift="Night Shift";
exports.basicInfo="Basic Info";
exports.firstname="First Name";
exports.lastname="Last Name";
exports.preferredPronoun="Preferred Pronounce";
exports.personalPhoneNumber="Personal Phone number"
exports.hometown="Hometown";
exports.address="Address";
exports.bloodgroup="Blood Group";
exports.ap="A+";
exports.an="A-";
exports.bp="B+";
exports.bn="B-";
exports.abp="AB+";
exports.abn="AB-";
exports.op="O+";
exports.on="O-";
exports.dateOfBirth="Date Of Birth";
exports.highestEducation="Highest Education";
exports.briefAboutEmployee="Brief About Employee";
exports.briefAboutMe="Brief About Me";
exports.askEmployee="Ask Employee About/Expertise";
exports.askMeAbout="Ask me about/Expertise";
exports.work="Work";
exports.department="Department";
exports.reportingTo="Reporting To";
exports.seatingLocation="Seating Location";
exports.employeeStatus="Employee Status";
exports.dateOfJoining="Date Of Joining";
exports.workPhone="Work Phone";
exports.companyName="Company Name";
exports.employeeType="Employee Type";
exports.role="Role";
exports.save="Save";
exports.cancel="Cancel";
exports.staffingLocationCountry="Staffing Location (Country)";
exports.staffingLocationState="Staffing Location (State)";
exports.staffingLocationCity="Staffing Location (City)";
exports.shiftingTimes="Shifting Times";

// Customizable Area End