import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  Tooltip,
  Modal,
  Badge, withStyles
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";




// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";


// Customizable Area End

import GamificationController, {
  Props,
  configJSON,
} from "./GamificationController";
import { arrow_left, cross, info_sqaure_bold, info_square, message_dots } from "./assets";
export default class Gamification extends GamificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  // Customizable Area End

  render() {
    const { hover, open, data } = this.state;
    return (
      // Customizable Area Start
      <>
        <div>
          <Box style={{ backgroundColor: '#F3F4F5' }}>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                height: '64px',
                fontWeight: 400,
                letterSpacing: '-0.26px',
                textAlign: 'left',
                color: '#000000',
                padding: '18px',
              }}
            >
              <img style={{ margin: '-2px 22px' }} src={arrow_left} alt="img" /> KPI Gamecard
            </Typography>
          </Box>
        </div>
        <Box style={{ padding: "20px 25px" }}>
          <Grid container spacing={2}>
            {data.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: item.chat ? '#FFFDF6' : "#F3F3F3",
                    padding: "14px 20px",
                    borderRadius: "8px",
                    border: item.chat ? '0.5px solid #FFCC00' : '0.5px solid #0000003E',

                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '350px' }}>
                    <Typography style={{ color: '#000000', fontFamily: 'Open Sans', fontSize: '18px', fontWeight: 700 }}>
                      {item.label}
                    </Typography>
                    <Typography style={{ color: '#000000', fontFamily: 'Open Sans', fontSize: '14px', fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <span>Weightage - {item.weightage} Points</span>
                      <Tooltip title={<div >
                        No Extra Leave : 10 Pts
                        1 Extra Leaves : 5 Pts
                        2+ Extra Leaves : 0 Pts
                      </div>} placement="right-start">
                        <img
                          style={{ marginLeft: '10px', height: '17px', width: '17px' }}
                          src={hover === index ? info_sqaure_bold : info_square}
                          data-test-id="onMouseEnter" 
                          onMouseEnter={() => this.handleMouseEnter(index)}
                          onMouseLeave={this.handleMouseLeave}
                          alt="info"
                        />
                      </Tooltip>
                    </Typography>
                  </div>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                    {item.chat ? (
                      <StyledBadge badgeContent={1} onClick={this.handleOpen} data-test-id="handleOpen" >
                        <img
                          style={{
                            height: '19px',
                            width: '21px',
                            filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(1)',
                          }}
                          src={message_dots}
                          alt="messages"
                          onClick={this.handleOpen}
                        />
                      </StyledBadge>
                    ) : (
                      <img
                        style={{
                          height: '19px',
                          width: '21px',
                          filter: 'unset',
                        }}
                        src={message_dots}
                        alt="messages"
                      />
                    )}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FFCC00",
                        color: "#000000",
                        borderRadius: "10px",
                        fontWeight: 600,
                        textTransform: "none",
                        padding: '6px 12px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                      }}
                    >
                      <span style={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: '#000000',
                      }}> Points - {item.points}</span>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Modal open={open} onClose={this.handleClose}>
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            padding: '26px 38px',
            borderRadius: '8px',
          }}>
            <Box style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 700,
                color: '#000000'
              }}>Timing</Typography>
              <Button onClick={this.handleClose}
              data-test-id="handleClose"
              style={{
                background: '#EBEBEB', padding: '11px', borderRadius: '6px',
                minWidth: '40px'
              }}><img src={cross} alt='X' /></Button>
            </Box>
            <Box style={{ margin: '24px 0 20px 0' }}>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }}>Date</span> <span style={{
                fontFamily: 'Open Sans',
                fontSize: "16px",
                fontWeight: 600,
                color: '#000000'
              }}
              >03-03-2024</span></Typography>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }} >Time of Arrival</span><span style={{
                fontFamily: 'Open Sans',
                fontSize: "16px",
                fontWeight: 600,
                color: '#000000'
              }}>10:15 AM</span></Typography>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }}>Status</span><span style={{
                color: '#FF5C5C', fontFamily: 'Open Sans',
                fontSize: "16px",
                fontWeight: 600
              }}>Late</span></Typography>
            </Box>
            <Box>
              <Typography style={{ fontFamily: 'Open Sans', fontSize: '14px', fontWeight: 700, color: '#000000', marginBottom: '5px', }}>Manager's Remark</Typography>
              <Box style={{ background: '#F7F7F7', padding: '14px', borderRadius: '8px', border: '0.25px solid #00000047' }}><span style={{ fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 400, color: '#000000' }}>Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.</span></Box>
            </Box>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledBadge = withStyles((theme) => ({
  badge: {
    minWidth: '14px',
    height: '14px',
    color: '#000000',
    backgroundColor: '#FFCC00',
    padding: 0,
    fontSize: '10px',
    fontWeight: 600,
  },
}))(Badge);
// Customizable Area End
