// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import { Toaster } from 'react-hot-toast';
import firebase from 'firebase'
import SuccessMessageScreen from "../../blocks/forgot-password/src/SuccessMessageScreen.web"
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web";
import EmployeeProfiles from "../../blocks/CustomisableUserProfiles/src/EmployeeProfiles.web"
import SetUserProfile from "../../blocks/CustomisableUserProfiles/src/SetUserProfile.web";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Download from "../../blocks/Download/src/Download";
import OTPInputAuthScreen from "../../blocks/otp-input-confirmation/src/OTPInputAuthScreen.web";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Gamification from "../../blocks/Gamification/src/Gamification";
import GamificationWeb from "../../blocks/Gamification/src/Gamification.web";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
// import Customform from "../../blocks/customform/src/CustomForm.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgetPassword from "../../blocks/forgot-password/src/ForgetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Notifications from "../../blocks/notifications/src/Notifications.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import MatchAlgorithm2 from "../../blocks/MatchAlgorithm2/src/MatchAlgorithm2";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailLoginBlock from "../../blocks/email-account-login/src/EmailLoginBlock.web";
import SignUpLandingPage from "../../blocks/email-account-login/src/SignUpLandingPage.web"
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import TaskList from "../../blocks/TaskList/src/TaskList.web";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Tasks from "../../blocks/tasks/src/Tasks";
import Task from "../../blocks/tasks/src/Task";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import Settings from "../../blocks/Settings/src/Settings.web";
import General from "../../blocks/Settings/src/General.web"
import VersionUpdate from "../../blocks/Settings/src/VersionUpdate.web"
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Users from "../../blocks/dashboard/src/Users.web";
import Template from "../../blocks/dashboard/src/Template.web"
import EditTemplate from "../../blocks/Gamification/src/EditTemplate.web"
import AddKpi from "../../blocks/Gamification/src/AddKpi.web"
import ViewKpi from "../../blocks/Gamification/src/ViewKpiDetails.web"
import ViewDkr from "../../blocks/Gamification/src/ViewDkrDetails.web"
import AddDkrDetails from "../../blocks/Gamification/src/AddDkrDetils.web"
import SingleUserUpload from "../../blocks/dashboard/src/SingleUserUpload.web"
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from "../../blocks/dashboard/src/Navbar.web";
import Sidebar from "../../components/src/Sidebar.web";
import EmployeeDashboard from "../../blocks/dashboard/src/EmployeeDashboard.web"
import CalendarComponent from "../../blocks/dashboard/src/Calendar.web"
import Department from "../../blocks/dashboard/src/Department.web"
import { getStateByCodeAndCountry } from "country-state-city/lib/state";
import { getStorageData } from "../../framework/src/Utilities";
import TemplateAdded from "../../blocks/dashboard/src/TemplateAdded.web";
import EditKpi from "../../blocks/dashboard/src/EditKpi.web";
import EditTemplate2 from "../../blocks/dashboard/src/EditTemplate2.web";
import AddDkr from "../../blocks/dashboard/src/AddDkr.web"
import AddDkr5 from "../../blocks/dashboard/src/AddDkr5.web"




const routeMap = {
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },

 
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },

  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: "/TermsAndConditions3"
  },
  MatchAlgorithm: {
    component: MatchAlgorithm,
    path: "/MatchAlgorithm"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2"
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: "/ServiceSpecificSettingsAdmin"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },

 
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OTPInputAuthScreen: {
    component: OTPInputAuthScreen,
    path: "/OTPInputAuthScreen"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgetPassword: {
    component: ForgetPassword,
    path: "/ForgetPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: "/MultilevelApproval"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  MatchAlgorithm2: {
    component: MatchAlgorithm2,
    path: "/MatchAlgorithm2"
  },
  UserGroups2: {
    component: UserGroups2,
    path: "/UserGroups2"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: "/EmailAccountRegistrationWeb"
  },
  SetUserProfile: {
    component: SetUserProfile,
    path: "/SetUserProfile"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  EmailAccountLoginBlock: {
    component: EmailLoginBlock,
    path: "/EmailAccountLoginBlock",


  },
  SignUpLandingPage:{
    component: SignUpLandingPage,
    path: "/"
  },
  SuccessMessageScreen: {
    component: SuccessMessageScreen,
    path: "/Success"
  },

  // LandingPage: {
  //   component: LandingPage,
  //   path: "/LandingPage"
  // },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },

  Home: {
    component: LandingPage,
    path: '/LandingPage',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const AdminAuthroutes = {
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  EmployeeDashboard: {
    component: EmployeeDashboard,
    path: "/EmployeeDashboard"
  },
  // AddUser:{
  //   component: Customform,
  //   path: "/createusers"
  // },
  SingleUserUpload:{
    component: SingleUserUpload,
      path: "/SingleUserUpload"
  },
  // EditUsers: {
  //   component: Customform,
  //   path: "/users/:id"
  // },
  Users: {
    component: Users,
    path: "/Users",
    exact: true
  },
  Template: {
    component: Template,
    path: "/Template"
  },
  TemplateAdded: {
    component: TemplateAdded,
    path: "/TemplateAdded"
  },
  EditTemplate2: {
    component: EditTemplate2,
    path: "/EditTemplate2"
  },
  EditKpi: {
    component: EditKpi,
    path: "/EditKpi"
  },
  AddKpi: {
    component: AddKpi,
    path: "/Addkpi"
  },
  ViewKpi: {
    component: ViewKpi,
    path: "/ViewKpi"
  },
  ViewDkr:{
    component: ViewDkr,
    path: "/ViewDkr"
  },
  AddDkr: {
    component: AddDkr,
    path: "/AddDkr"
  },
  AddDkr5: {
    component: AddDkr5,
    path: "/AddDkr5"
  },
  Calendar: {
    component:CalendarComponent,
    path:"/calendar",
    exact:true
  },
  Department: {
    component:Department,
    path:"/department",
    exact:true
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  EditTemplate: {
    component: EditTemplate,
    path: "/EditTemplate/:id",
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
}


const EmployeeAuthroutes = {
  
  EmployeeDashboard: {
    component: EmployeeDashboard,
    path: "/EmployeeDashboard"
  },
  GamificationWeb:{
    component: GamificationWeb,
    path: "/GamificationWeb"
  },
  EmployeeProfiles: {
    component: EmployeeProfiles,
    path: "/EmployeeProfiles"
  },
  Calendar: {
    component:CalendarComponent,
    path:"/calendar",
    exact:true
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  General: {
    component:General,
    path:"/General"
  },
  VersionUpdate: {
    component:VersionUpdate,
    path:"/VersionUpdate"
  },
  Settings: {
    component: Settings,
    path: "/Settings",
    // exact:true
  },
}

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});
class App extends Component {
  state = {
    loggedin: false,
    flag:false
  }

  dataFromStorage = async () => {
    let result = await getStorageData("loginMessage")
    if (result === "org_admin") {
      this.setState({ flag: true })
    }
    else {
      this.setState({ flag: false })
    }
  }

  async componentDidMount() {
    const message=await getStorageData("loginMessage")
    document.addEventListener('isLoggedIn', ({ detail }) => {
      this.setState({
        loggedin:detail.loggedIn,
        flag:detail.useType=="org_admin"? true :false
        
      })
    })
    this.dataFromStorage()
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const loggedin = window.localStorage.getItem('authToken');

    const AuthComponent = () => {
  
      return (
        this.state.flag ?
        <Switch>
          <div className="main-wrapper">
            <Sidebar />
            <div className="page-wrapper">
              <Navbar />
              <Route exact path="/" render={() => <Redirect to="/Dashboard" />} />
              <Route exact path="/EmailAccountLoginBlock" render={() => <Redirect to="/Dashboard" />} />
             
              {WebRoutesGenerator({ routeMap:AdminAuthroutes })}
    
            </div>
          </div>
        </Switch>
        :
        <Switch>
        <div className="main-wrapper">
          <Sidebar />
          <div className="page-wrapper">
            <Navbar />
            <Route exact path="/" render={() => <Redirect to="/EmployeeDashboard" />} />
            <Route exact path="/EmailAccountLoginBlock" render={() => <Redirect to="/EmployeeDashboard" />} />
            {WebRoutesGenerator({ routeMap:EmployeeAuthroutes })}
           
          </div>
        </div>
      </Switch>
    
      );
    };
    
    
     const AppLayoutComponents = routeMap => {
      return (
        <div>
          <Switch>
            {WebRoutesGenerator({ routeMap })}
            <Route exact path="*" render={() => <Redirect to="/EmailAccountLoginBlock" />} />
            <Redirect from="*" to="/EmailAccountLoginBlock" /> 
          </Switch>
          </div>
      )
    };

    return (
      <View >
         {/* <TopNav />  */}
        <Toaster />
        {loggedin || this.state.loggedin
          ? AuthComponent()
          : AppLayoutComponents(routeMap)
        }
        {/* {WebRoutesGenerator({ routeMap })} /} */}
        <ModalContainer />
      </View>

    );
  }
}

export default App;