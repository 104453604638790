import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  type: string;
  dropdown: string;
  KpiChange: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      type: "Slab",
      dropdown:"Number",
      KpiChange:"KPI1"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }



    // Customizable Area Start
    // Customizable Area End
  

  // web events
  navigateToEditTemplate2=()=>{
    this.props.navigation.navigate("EditTemplate2")
  };
  navigateToEditKpi=()=>{
    this.props.navigation.navigate("EditKpi")
  }

  navigateToAddDKR5=()=>{
    this.props.navigation.navigate("AddDkr5")
  }
  handleCalculationTypeChange = (event:any) => {
    this.setState({ type: event.target.value });
  };
  handleCalculationDropdownChange = (event:any) => {
    this.setState({ dropdown: event.target.value });
  };
  handleCalculationKpiChange = (event:any) => {
    this.setState({ dropdown: event.target.value });
  };

 
  // Customizable Area Start
  // Customizable Area End
}
