export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const active_filter = require("../assets/active_filter.png");
export const inActive_filter = require("../assets/inactive_filter.png");
export const fileNotFound = require("../assets/file-not-found.png");
export const success_event = require("../assets/successevent.png");
export const arrow_left = require("../assets/arrow-left.png")
export const message_dots = require("../assets/message_dots.png")
export const info_square=require("../assets/info_Square.png")
export const info_sqaure_bold=require("../assets/info_Square_Bold.png")
export const cross=require("../assets/cross.png")