import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-hot-toast";
import React, { FormEvent } from "react";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  onClose: () => void;
  message: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isHovered:boolean,
  showMoreRow:boolean,
  selectedDepartment: string,
  navigateToEditKpi: boolean,
  kpiName:string,
  automaticChecked: boolean,
  kpiDescription:string,
  weightage:string,
  goalCalculation:string,
  runRateChecked: boolean;
  analyticsChecked: boolean;
  startDate:any;
  endDate:any;
  dataSource: any;
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  options: any;
  series:any;
  dataSource2: any;
  viewAllUserData: any;
  activateMessage: string;
  department: unknown;
  percentage: number;
  togglemodel: boolean;
  addFeature: boolean;
  imageData: any;
  switchStates: { id: number | string }[];
  viewAllTemplates: any
  kpis: any;
  templateData: { name: string, description: string },
  selectedItemId: any,
  searchQuery: string,
  flagButton:boolean,
  events:any,
  isPopupVisible:boolean;
  open:boolean,
  isMultiple: boolean,
  isChecked:boolean,
  openNotification:boolean,
  editAdminFormData:any,
  profile_img:string,
  userDataLoading:boolean,
  totalTemplate: number,
  departmentListData: any,
  roleListData: any,
  activeUsersCount:number,
  imageField:string|null,
  tempLateoptions:string[],
  createEventData:{  
    event_name: string,
    description: string,
    day_type: string,
    start_date: string,
    end_date: string,
  },
  disableKpiModel:boolean
  copyTemplateData:any
  copiedTemplates: any,
  selectedCheckboxes : {
    all_templates: boolean;
    copied_templates: boolean;
    original_templates: boolean;
  };
  filterData:any;
  showTemplateData:any;
  showKpiDiv:boolean;
  selectedKPIName: string, 
  selectedKpiIds: number,
  disableSwitch:boolean,
  kpiFormData:any,
  anchorEl:any
  showModal: boolean, 
  accountIdToDisable: any,
  accountsName:string,
  sideBarFlag:boolean,
  userType : string;
  cards:any,
  isOpen:boolean
  isduplicateOpen:boolean,
  department_id :any ,
  editOpen: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
 apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllUserId: string = "";
  getActivatedAccountsId: string = "";
  getTemplateId: string = "";
  getEditTemplateId: string = "";
  getSearchTemplateID: string = "";
  bulkUploadInput: any;
  userBulkUploadId:string="";
  addMoreFeatureId:string="";
  totalTemplateId: string = "";
  getDepartmentsID: string = "";
  getRolesID: string = "";
  getActiveUsersId:string = "";
  createEventId:string="";
  getCopyTemplateID:string="";
  getFilterTemplateID:string="";
  getShowTempaltewithKpiId:string="";
  getDisableKpiId:string="";
  getUpdateTemplateKpiId:string=""
  getAdminProfileDetails: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    
    this.state = { 
      isHovered:false,
      showMoreRow:false,
      selectedDepartment: 'choose department',
      navigateToEditKpi: false ,
      editOpen: false,
      automaticChecked: true,
      runRateChecked: true,  
      analyticsChecked: true,
      kpiName: '',
      kpiDescription: '',
      weightage: '',
      goalCalculation: '',
      sideBarFlag:false,
      department_id: null,
      accountsName:"",
      showModal: false,
      accountIdToDisable: null,
      anchorEl:null,
      kpiFormData:{},
      disableSwitch:true,
      selectedKpiIds:0,
      selectedKPIName: '', 
      showKpiDiv:true,
      showTemplateData:{},
      filterData:[],
      selectedCheckboxes: {
        all_templates: false,
        copied_templates: false,
        original_templates: false,
      },
      copiedTemplates: {},
      copyTemplateData:{},
      disableKpiModel:false,
      createEventData:{
        event_name: '',
        description: '',
        day_type: '',
        start_date: '',
        end_date: '',
      },
      isOpen:false,
      isduplicateOpen:false,
      imageField:null,
      activeUsersCount:0,
      totalTemplate: 0,
      roleListData: [],
      departmentListData: [],
      openNotification:false,
      isChecked:false,
      isMultiple: false, 
      editAdminFormData: [],
      profile_img: '',
      userDataLoading: false,
      startDate:null,
      endDate:null,
      open:false,
      isPopupVisible:false,
      events: [
        {
          date: "2023-07-02",
          color: "red",
          extraData: "sdfsd"
        },
        {
          date: new Date("2023-07-23"),
          color: "yellow",
          extraData: "sdfsd"
        },
        {
          date: "2023-07-14",
          color: "#ffafcc",
          extraData: "sdfsd"
        }
      ],
      flagButton:false,
      switchStates: [],
       templateData: {
        name: "",
        description: "",  
      },
     kpis:[],
      selectedItemId: null,
      searchQuery: '',
      viewAllTemplates: [],
       imageData: '',
     dashboardData: [],
      addFeature: false,
      togglemodel: false,
      percentage: 60,
      department: "",
      viewAllUserData: [],
      userType : "",
      errorMsg: "",
      token: "",
      loading: false,
      activateMessage: "",
      series: [
        {
          name: "Inflation",
          data: [2.3, 3.1, 4.0, 10.1, 4.0],
          color: "#476484"
        }
      ],
      tempLateoptions: ['All Templates', 'Original Templates', 'Duplicate Templates', 'Added Templates'],
      options: {
        
        chart: {
          type: "bar",

          toolbar: {
            show: false
          }
        },
        title: {
          text: "Company Usage",
          align: "left",
          style: {
            color: "#a0a0a0",
            fontSize: '20px', fontWeight: 'bold',
            paddingLeft: 20,
            paddingTop:20
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            },
            borderRadius:"5"
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#9f9f9d"]
          },
          offsetY: -20,
          formatter: function (val: any, opts: any) {
            return opts.w.globals.labels[opts.dataPointIndex]
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: ["XYZ Ltd.", "Awes Ltd.", "Onm Ltd.", "123 Ltd.", "Swr Ltd."],
          title: {
            text: "Company usage based on the KPI Templates created",
            style: {
              color: "#C9C9C9" 
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          },
          labels: {
            show: true,
            offsetY: 10,
          },
          
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return "$ " + val + " thousands";
            }
          }
        }
      },
      dataSource:  [
        {
          name: "Aug 2022",
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: "Sep 2022",
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: "Oct 2022",
          uv: 2000,
          pv: 9800,
          amt: 2290
        },
        {
          name: "Dec 2022",
          uv: 2780,
          pv: 3908,
          amt: 2000
        },
        {
          name: "Jan 2023",
          uv: 1890,
          pv: 4800,
          amt: 2181
        },
      ],
      dataSource2:  [
        {
          name: "Aug 2020",
          uv: 4000,
          pv: 2400,
          amt: 2400
        },
        {
          name: "Sep 2020",
          uv: 3000,
          pv: 1398,
          amt: 2210
        },
        {
          name: "Oct 2020",
          uv: 2000,
          pv: 9800,
          amt: 2290
        },
        {
          name: "Nov 2020",
          uv: 2780,
          pv: 3908,
          amt: 2000
        },
        {
          name: "Dec 2020",
          uv: 1890,
          pv: 4800,
          amt: 2181
        },
        {
          name: "Jan 2021",
          uv: 2390,
          pv: 3800,
          amt: 2500
        },
        
      ],
      cards:[
        { title: 'Developer Gamecard', content: 'Over 92% of computers are infected with Adware and spyware. Such software is rarely accompanied by uninstall utility and even when it is it almost always leaves broken Windows Registry keys behind it.' , status: 1},
       ]
     
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getAdminProfileData()
    setTimeout(() => {
      const medianValue = this.calculateMedianValue();
      const options = {
        ...this.state.options,
        annotations: {
          yaxis: [
            {
              y: medianValue,
              borderColor: "#E4E4E4",
              strokeDashArray: 10
            }
          ]
        }
      };

      this.setState({ options });
    }, 1000);
    this.getDashboardData();
    this.getAllTemplates();    
    this.getTotalTemplates();
    this.getActiveUsers();
    this.getDepartmentList();
    if(this.props.navigation && this.props.navigation.match.path=="/department"){
      this.getDepartmentList();
      this.getRolesList()
    }
    
    if(this.props.navigation && this.props.navigation.getParam("id")){

      this.showTemplateswithKpis(this.props.navigation.getParam("id"))
    }
    this.dataFromStorage()

    let result = await getStorageData("loginMessage")
    this.setState({ userType : result })
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    const fetchallUSersHeader = {
      "Content-Type": configJSON.dashboarContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewAllEndPoint}/?company_name=${window.localStorage.getItem(
        "cmpName"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchallUSersHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getAllUserId:
          if (responseJson && responseJson.data) {
            this.setState({ viewAllUserData: responseJson.data });
          }
          break;
        case this.getActivatedAccountsId:
          if (responseJson && responseJson.message) {
            toast.success(responseJson.message)
            this.handleOpenKpiModal()
            this.getDashboardData();
            this.getActiveUsers();
            
          }
          break;
      
        case this.getTemplateId:
          this.getTemplate(responseJson)
          break;
        case this.getEditTemplateId:
          if(responseJson.data){
            this.handleClose();
            this.handleOpenModal()
            this.showTemplateswithKpis(this.props.navigation.getParam("id"))
          }
          break;
        case this.getSearchTemplateID:
          this.getSearchTemp(responseJson)
          break;
        
        case this.userBulkUploadId:
           this.uploadBulkData(responseJson)
            break;
        case this.getDepartmentsID:
              this.getDepartmentDataList(responseJson)
              break;
        case this.getRolesID:
              this.getRolesDataList(responseJson)
              break;
        case this.totalTemplateId:
              this.getTotalTemplate(responseJson)
              break;
        case this.getActiveUsersId:
            this.getActiveUsersResponse(responseJson)
                break;
        case this.createEventId:
            this.createEventResponse(responseJson)
              break;
       case this.getCopyTemplateID:
            this.getCopyresponse(responseJson)
            break;
        case this.getFilterTemplateID:
          this.getFilterTemplateResponse(responseJson)
          break;
        case this.getShowTempaltewithKpiId:
          this.getShowTemplateDataKpisResponse(responseJson)
          break;
        case this.getDisableKpiId:
          this.handleDisaleSwitchResponse(responseJson)
          break;
        case this.getUpdateTemplateKpiId:
            this.getUpdateKpiResponse(responseJson)
            break;
        case this.getAdminProfileDetails:
            this.getEditEmployeesFormData(responseJson)
            break;
          default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  dataFromStorage = async () => {
    let result = await getStorageData("loginMessage")
    
    if (result === "org_admin") {
      this.setState({ sideBarFlag: false })
    }
    else {
    
      this.setState({ sideBarFlag: true })
    }
  }

  handleCardClick = () => {
    const { cards } = this.state;
    const newCard = { title: `Developer Gamecard (${cards.length + 1})`, content: `Over 92% of computers are infected with Adware and spyware. Such software is rarely accompanied by uninstall utility and even when it is it almost always leaves broken Windows Registry keys behind it.`, status : 0 };

    this.setState({
      cards: [...cards, newCard],
    });
  };

  togglePopup = () => {
    this.setState({openNotification:!this.state.openNotification})
   };

   conditionSort = (condition: any, trueResult: any, falseResult: any) => {
    return condition ? trueResult : falseResult;
  }

  uploadBulkData=(responseJson:any)=>{

  if(responseJson && responseJson.message){
    this.getDashboardData()
    toast.success(responseJson.message)
  }
  else{
    toast.error("file is already uploaded")
  }
}

  handleFileChange=(event:any)=>{
   
    const file=event.target.files[0]
  
    if(file){
      if(file.type !== 'text/csv'){
        toast.error("select a csv file");
        return;
      }
      this.uploadBulkUsers(file)
    } 
  }
  navigateToSignUp=()=>{
    this.props.navigation.navigate("EditKpi")
  }
  navigateToTemplateAdded=()=>{
    this.props.navigation.navigate("TemplateAdded")
  }
  navigateToEditTemplate2=()=>{
    this.props.navigation.navigate("EditTemplate2")
  }
  navigateToAddDKR=()=>{
    this.props.navigation.navigate("AddDkr")
  }
  handleCheckboxChange = (event:any) => {
    const { name, checked } = event.target;
    if (name === "isMultiple") {
      this.setState({
        isMultiple: checked
      });
    } 
    else{
      this.setState({
        isChecked: checked
      });
    }
  };

   handleOpenModal = () => {
    this.setState({open:!this.state.open,isPopupVisible: false });
    setTimeout(() => {
    this.setState({open:false,isPopupVisible: false });
    }, 5000);
  };
  handleOpenKpiModal = () => {
    this.setState({disableKpiModel:!this.state.disableKpiModel });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleEditClick = (item:any) => {
    localStorage.setItem("editEmployeeName", item.attributes.name);
    localStorage.setItem("editEmployeeID", item.attributes.employee_id);
    window.history.pushState({},`/users/${item.id}`);
  };
  handleTemplatCheckboxChange = (name:any) => {
    
    this.setState(
      (prevState:any) => {
        const updatedCheckboxes = {
          all_templates: false,
          copied_templates: false,
          original_templates: false,
        };
  
        updatedCheckboxes[name as keyof typeof updatedCheckboxes] = !prevState.selectedCheckboxes[name];
  
        return {
          selectedCheckboxes: updatedCheckboxes,
        };
      }
    );
  };

  handleKPISelection = (index:number) => {
    const selectedKPIName = this.state.showTemplateData.attributes.kpis.data[index].attributes.name;
    const selectedKpiIds = this.state.showTemplateData.attributes.kpis.data[index].id;
    this.setState({ selectedKPIName,selectedKpiIds });
  };
  handleClick = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMouseOver = () => {
    this.setState({ isHovered: true });
  };

  handleMouseOut = () => {
    this.setState({ isHovered: false });
  };
   
  handleCheckboxChange2 = (field: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [field]: event.target.checked } as unknown as Pick<S, keyof S>);
  };
  handleCheckboxChange3 = (field: keyof 2) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [field]: event.target.checked } as unknown as Pick<S, keyof S>);
  };
  handleSelectChange = (id: number) => {
    this.setState({ department_id: id }, () => {
      this.getAllTemplates();
    });
  };
  
  openPopup = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible, createEventData: {
        event_name: '',
        description: '',
        day_type: '',
        start_date: '',
        end_date: '',
      },
    });
  };

  getDepartmentDataList = (responseJson: any) => {
    if (responseJson.departments) {
      this.setState({ departmentListData: responseJson.departments });
    }
  }

  getRolesDataList = (responseJson: any) => {
    if (responseJson.role) {
      this.setState({ roleListData: responseJson.role });
    }
  }

  getShowTemplateDataKpisResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ showTemplateData: responseJson.data });
    }
  }


  customTileContent = ({ date, view }:any) => {
    // Get the current date
    const currentDate = new Date();
  
    // Check if the current date matches the date being rendered
    if (
      date.getDate() === currentDate.getDate() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getFullYear() === currentDate.getFullYear()
    ) {
    
      return <span className="indicator"></span>;
    }
  
    
  
    // Find the event for the current date
    const events = [
      { date: new Date(2023, 6, 31), title: "Event 1", color: "red" },
      { date: new Date(2023, 7, 5), title: "Event 2", color: "green" },
      { date: new Date(2023, 7, 15), title: "Event 3", color: "red" }
    ];
  
    // Find the event for the current date
    const event = events.find(
      (event) =>
        event.date.getDate() === date.getDate() &&
        event.date.getMonth() === date.getMonth() &&
        event.date.getFullYear() === date.getFullYear()
    );
   
  
    // Show a bullet marker with the specified color for the event
    if (event) {
      return (
        
          <span className="bullet" style={{ backgroundColor: event.color }} />
      
      );
    }
  
    
   
  };

   formatMonthLabel = (locale:any, date:any) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const day = startOfMonth.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const month = startOfMonth.toLocaleString('en-US', { month: 'long' }); // Get the month name
    const year = startOfMonth.getFullYear(); // Get the year

    const Eday = endOfMonth.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    const Emonth = endOfMonth.toLocaleString('en-US', { month: 'long' }); // Get the month name
    const Eyear = endOfMonth.getFullYear(); // Get the year

    const formattedStartDate = `${day} ${month} ${year}`;
    const formattedEndDate = `${Eday} ${Emonth} ${Eyear}`;
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  
   handleUploadClick = () => {
   this.setState({flagButton:!this.state.flagButton})
  
  };
  handlePopOverClick = (event:any) => {
    this.setState({anchorEl:event.currentTarget})
    this.getAllTemplates()
   };
   handlePopOvercloseClick = () => {
    this.setState({anchorEl:null})
    
   };

  getStatusStyle = (hasData:any, activated:any) => {
    if (!hasData) {
      return { color: '#ff4f4f' };
    } else if (activated) {
      return { color: '#9298a1' };
    } else {
      return { color: '#00bea0' };
    }
  };

logout = async() => {
    localStorage.removeItem("authToken");
    await removeStorageData("loginMessage")
    await removeStorageData("cmpName")
    await removeStorageData("adminName")
    
    document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: false }));
    window.history.pushState({}, "", "/EmailAccountLoginBlock");
  }
  calculateMedianValue = () => {
    const { data } = this.state.series[0];
    const sortedData = [...data].sort((a, b) => a - b);
    const medianIndex = Math.floor(sortedData.length / 2);
    return sortedData.length % 2 === 0
      ? (sortedData[medianIndex] + sortedData[medianIndex - 1]) / 2
      : sortedData[medianIndex];
  };

  formatDate = (date: any) => {
    return date.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric"
    });
};

  calculateMedian = (data:any) => {
    const sortedData = [...data].sort((a, b) => a.pv - b.pv);
    const dataLength = sortedData.length;

    if (dataLength % 2 === 0) {
      const middleIndex = dataLength / 2;
      const middleValues = [
        sortedData[middleIndex - 1].pv,
        sortedData[middleIndex].pv
      ];
      const medianValue = (middleValues[0] + middleValues[1]) / 2;
      return medianValue;
    } else {
      const middleIndex = Math.floor(dataLength / 2);
      const medianValue = sortedData[middleIndex].pv;
      return medianValue 
    }
  };

  
  getTotalTemplate = (responseJson: any) => {
    if(responseJson&&!responseJson.errors){
      this.setState({ totalTemplate: responseJson });
    }else if (responseJson && responseJson.errors && responseJson.errors[0].token){
      localStorage.removeItem("authToken");
      document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: false }));
      window.history.pushState({}, "", "/EmailAccountLoginBlock");
    }
  }
  getTemplate = (responseJson: any) => { 
    if (responseJson && !responseJson.errors) {
      this.setState({ viewAllTemplates: responseJson.templates });
    } else {
      this.setState({ viewAllTemplates: [] });
    }
  }
  getActiveUsersResponse= (responseJson: any) => {
    if (responseJson) {
      
      this.setState({ activeUsersCount: responseJson.active_users });
    }
  }
  getSearchTemp = (responseJson: any) => {
  
    if (responseJson.data) {
      this.setState({ viewAllTemplates: responseJson.template.data });
    } else {
      this.setState({ viewAllTemplates: [] });
    }
  }
  getFilterTemplateResponse=(responseJson: any) => {
    
    if (responseJson) {
      this.setState({ viewAllTemplates: responseJson.template.data});
    } 
    
  }
  createEventResponse= (responseJson: any) => {
    
    if (responseJson.message) {
    this.setState({activateMessage:responseJson.message})
     this.handleOpenModal()
    } 
  }
  getCopyresponse= (responseJson: any) => {
    if (responseJson.data) {
    this.setState({copyTemplateData:responseJson.data})
     this.getAllTemplates()
    } 
    else{
      toast.error(responseJson.errors)
    }
  }
  getEditEmployeesFormData = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        profile_img: responseJson.data.attributes.image,
        editAdminFormData: responseJson.data,
        userDataLoading: false
      });
    }

  }
  getAdminProfileData = () => {
    let userToken = window.localStorage.getItem("authToken");

    const httpHeaderData = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeaderData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getUpdateKpiResponse= (responseJson: any) => {
    if (responseJson.data) {
      this.showTemplateswithKpis(this.props.navigation.getParam("id"))
     this.props.navigation.navigate("Template")
    } 
  }

  async  getBase64(file:any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise(resolve => reader.onload = resolve);
    return reader.result;
  }

  handleUpdateKpiForm = (values: { [key: string]: any }) => {
    
     const searchParams = window.location.pathname.split("/");
     const params = searchParams[searchParams.length - 1]
     const paramsAsNumber = Number(params);
    
    this.setState({ kpiFormData: values });
     this.getUpdateTEmplateKpiDetail(paramsAsNumber)
     
   };

    createFormData = (values:any) => {
    const formData = new FormData();
    
    formData.append('template[name]', values.templateName);
    formData.append('template[description]', values.templateDescription);
    
    for (const key in values) {
      if (key.startsWith('kpiname')) {
        const index = key.replace('kpiname', ''); 
        formData.append(`template[kpis_attributes][${index}][name]`, values[key]);
      } else if (key.startsWith('kpidescription')) {
        const index = key.replace('kpidescription', '');
        formData.append(`template[kpis_attributes][${index}][description]`, values[key]);
      } else if (key.startsWith('points')) {
        const index = key.replace('points', ''); 
        formData.append(`template[kpis_attributes][${index}][point]`, values[key]);
      }
      else if (key.startsWith('id')) {
        const index = key.replace('id', ''); 
        formData.append(`template[kpis_attributes][${index}][id]`, values[key]);
      }
    }
    return formData;
};
  handleAddFeature = () => {
    this.setState((prevState) => ({
      kpis: [...prevState.kpis, { name: '', description: '' }],
    }));
  };


  handleFeatureChange = (index: any, value: any) => {
  
    this.setState((prevState) => {
      const updatedKPIs = [...prevState.kpis];
      updatedKPIs[index].name = value;
      return { kpis: updatedKPIs };
    });
  
  };

  handleDescriptionChange = (index: any, value: any) => {
    this.setState((prevState) => {
      const updatedKPIs = [...prevState.kpis];
      updatedKPIs[index].description = value;
      return { kpis: updatedKPIs };
    });
  };

  handleOpenAddKpiModel = () => {
    
    
    this.setState({
     
      togglemodel: true,
      addFeature: false,});
  };

   addKpi = () => {
    this.setState(prevState => ({
      kpis: [...prevState.kpis, prevState.kpis.length + 1]
    }));
  };

  toggleModal = () => {
    this.setState((prevState) => ({ isduplicateOpen: !prevState.isduplicateOpen }));
  };
  handleClose = () => {
    this.setState({ togglemodel: false, addFeature: false ,isOpen: false,isduplicateOpen:false,open :false});
  };
  
  handleSwitchChange = (id: number | string,name:string) => {
    this.setState({ accountIdToDisable: id,accountsName:name });
  };
  confirmDisableAccount = () => {
   this.getActivateAccount(this.state.accountIdToDisable)
    const updatedSwitchStates = [...this.state.switchStates];
  const index = updatedSwitchStates.findIndex(state => state.id === this.state.accountIdToDisable);
  if (index === -1) {
    updatedSwitchStates.push({ id: this.state.accountIdToDisable });
  }

  this.setState({
    switchStates: updatedSwitchStates,
    accountIdToDisable: null,
  });
  };
  
  getClassName=(attributes:any) =>{
    return attributes ? "table-data-row" : "disable";
  }
  
  handleEventFormSubmit = (event:any ) => {
    event.preventDefault();
    this.createEvent()
  }
  handleDisaleSwitchResponse=(responseJson:any)=>{
    if(responseJson.message){
      this.showTemplateswithKpis(this.props.navigation.getParam("id"))
      this.handleOpenKpiModal()
    }
  }
  getActivateAccount = async (id: number | string) => {
    let userToken = window.localStorage.getItem("authToken");
    const reqbody = { id };
    const fetchactvateAccountsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivatedAccountsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  
  getAllTemplates = () => {

    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=${this.state.department_id}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  uploadBulkUsers = async (file:any) => {

    const formData = new FormData()
    formData.append("file",file)

    const header = {
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userBulkUploadId = requestMessage.messageId; 
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.uploadBulkUserEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getDepartmentList = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchdepartmentsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDepartmentsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.departmentEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchdepartmentsHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRolesList = async () => {
    

    const fetchdepartmentsHeader = {
      "Content-Type": configJSON.dashboarContentType,
      
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rolesEndPoint}/?department_id=${window.localStorage.getItem(
        "departmentId"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchdepartmentsHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getTotalTemplates = async () => {
    let userToken = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.totalTemplateId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.totalTemplateEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getActiveUsers = () => {
    const fetchTemplateHeader = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActiveUsersId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activeUsersEndPoint}/?company_name=${window.localStorage.getItem(
        "cmpName"
      )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEvent = async () => {
    const fetchactvateAccountsHeader = {
      "Content-Type": configJSON.dashboarContentType,
    };
    
    const reqbody = {
     
    event:this.state.createEventData
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEventId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEventEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqbody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showTemplateswithKpis = async (id:number) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShowTempaltewithKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     
     `${configJSON.showTemplatesEndPoint}/${id}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  disableKpisSwitch = async () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
     
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDisableKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     
     `${configJSON.disableKpiEndPoint}/?id=${this.state.selectedKpiIds}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUpdateTEmplateKpiDetail = async(id: number) => {
    
    const formData=this.createFormData(this.state.kpiFormData)
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpdateTemplateKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.showTemplatesEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.activateAccountApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  KpiGamecard = (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  // Customizable Area End

}
